import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Financial Reporter: In The Spotlight with Chris Pearson, HSBC UK</h1>
    <img src="https://www.financialreporter.co.uk/images/660x350/chris_pearson_hsbc-8460.jpg" alt="Chris Pearson" title="Chris Pearson photo" className="news-article__lead-image" />
    <blockquote>"Our proportion of intermediary lending is now well in excess of 60% of our gross mortgage lending."</blockquote>
    <p>We spoke to Chris Pearson, head of intermediary mortgages at HSBC UK, about the history of HSBC's intermediary proposition, how it supported brokers throughout the Covid-19 pandemic, and what's next for HSBC's mortgage journey.</p>
    <p><strong>FR: You’ve just reached the milestone of 500 broker partnerships; tell us a bit about the history of your entry into the intermediary market.</strong></p>
    <p>The history of HSBC goes back to 1865, but it wasn’t until 1979, when many will have known us as the Midland Bank, that we first started offering mortgages in the UK. Up until then it was predominantly the domain of regional building societies. The average house price was £17,000 and the Bank of England interest rate was seventeen per cent. It seems a world away from today.</p>
    <p>The last forty years have seen seismic shifts in the housing market and financial services, and we have been at the forefront of pushing boundaries – we were the first provider to launch fixed rate mortgages in the UK. We were also the first lender to offer a variable rate discount in addition to being one of the first to advertise on TV.</p>
    <p>We have historically wholly focused on ‘direct’ mortgages. Considering we had between 15% and 20% market share in other areas of personal finance, we were extremely underweight when it came to mortgages. We had a great appetite to build on that and lend more.</p>
    <p>A change in approach led us to dip our toes into the broker waters so we carried out a pilot with a couple of large broker firms. It worked well. We were able to provide access to our mortgages to more customers, particularly first time buyers who tend to use brokers. We slowly and methodically added broker firms to ensure we could handle the demand and provide the service that brokers want (for them and their customers) and deserve.</p>
    <p>Our proportion of intermediary lending is now well in excess of 60% of our gross mortgage lending. Our presence in the broker market is going from strength to strength, with extremely competitive products, often market-leading and a real commitment to high levels of service for brokers.</p>
    <p><strong>FR: How have you supported brokers throughout the Covid-19 pandemic?</strong></p>
    <p>The pandemic has been challenging for every single person in the country in different ways, from personal impacts of losing loved ones, to business impacts where ways of working have been significantly disrupted.</p>
    <p>Like everyone else, many colleagues across HSBC have also been impacted both personally and in a business respect. While there was a hiatus in the housing market with property viewings and valuations not able to take place, there was still plenty of work to be done with rate switchers, remortgages and also mortgage applications that were already in the pipeline pre-pandemic. We know that brokers rely on us, and we wanted to do our very best to support every single broker and not let any of them down.</p>
    <p>Communication with our broker partners is of paramount importance. We need them to know what is happening, as much as they need to know what is happening themselves, and keeping lines of communication open from the very start was the aim, despite the challenges we faced as a result of Covid-19.</p>
    <p>Obviously, the pandemic took everybody by surprise, but I am extremely proud of how colleagues across HSBC UK and also our broker partners set themselves up to work from home with no notice. With the rapid deployment of mortgage payment holidays being a key feature of the mortgage market’s response to the pandemic, we also kept the show on the road with world class support over the phone and live chat, increasing desk top and automated valuations, removing paper based packaging requirements and keeping access to 90% LTV mortgages for much longer than most other lenders. Our brokers have been simply stunning in their ability to adapt to these rapid and unprecedented changes to the market by working with us in a pragmatic and understanding way, always keeping customers informed and aware of the rapidly evolving mortgage market.</p>
    <p><strong>FR: How has technology improved the mortgage process over the past few years and how can tech continue to transform the process?</strong></p>
    <p>Historically, technological innovation in the mortgage industry has been relatively steady, but this is starting to evolve more rapidly. Customers are now managing more aspects of their lives online and are becoming increasingly confident using digital channels to search for complex products such as mortgages. If anything, the pandemic has accelerated customers’ transition to online and mobile channels across most aspects of our daily lives. Innovative technologies and digital processes are becoming more common throughout the mortgage journey from sourcing, through to application and finally completion.</p>
    <p>There is a growing demand from customers to be able to complete simple mortgage tasks online, with like for like product transfers being a typical example. There are lots of great examples of third party ‘broker tools’ integrating across CRM systems and into lender platforms to help speed up the end to end process and cut down on duplication, additional tasks as well as rapid product and policy search tools. I can only see adoption of these systems increasing across both the broker and lending communities over the next few years.</p>
    <p>One of the keys to genuinely improve the speed and efficiency of the application and underwriting stages is to be able to get as much information as possible on the customer and their circumstances from appropriate and trusted external data sources, meaning customers only need to provide the basic details.</p>
    <p>Significant inroads have already been made around ID verification and property valuation however, a new technological development which is set to transform the mortgage journey is Open Banking. Open Banking is the perfect example of technology as a game-changer in mortgage processing. With the customer’s express permission, lenders get to see specific financial information needed, such as bank statements, to make a decision without waiting for documents to be gathered and uploaded.</p>
    <p>Technology will continue to transform the mortgage journey and there is no question that lenders and brokers will need to continue to embrace innovation in order to attract and retain customers.</p>
    <p><strong>FR: What’s next for HSBC’s mortgage journey?</strong></p>
    <p>Simply put, we will continue to invest in people, products, policies and processes (the 4 Ps if you like) that will deliver the very best service to our brokers. We’ve taken a very deliberate approach to on-boarding new brokers at a pace that allows us to serve our whole panel with the best possible service, without over-stretching our resources just to add to those numbers. We’ll continue to grow our presence in the broker market through a logical on-boarding strategy and deliver products that offer great value for money and features that brokers and customers want.</p>
    <p><strong>FR: If you could read one headline about the mortgage market in 2021, what would it be?</strong></p>
    <p>One of the most impressive collaborative efforts I’ve seen in recent years has been the industry’s response to the winding down of the stamp duty ‘holiday’ at the end of June this year. A true partnership effort across brokers, lenders, solicitors, valuers, estate agents and of course customers, resulted in a really positive outcome for the vast majority of completions at the busiest time for the marker in recent memory. It demonstrates what can be done when we all work together to get the very best outcome for our customers. It is what defines our market as a truly great place to work and be proud of.</p>
  </NewsArticle>
)

export default Article
